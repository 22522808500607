/* 全体のスタイル */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* 画面全体の高さに対応 */
}

/* ナビゲーションメニュー */
.page-specific nav {
  background-color: #4caf50;
  padding: 10px;
}

nav ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  text-decoration: none;
  color: white;
  font-size: 18px;
}

nav ul li a:hover {
  color: #d4ffd4;
}

/* 各ページのスタイル */
h2 {
  color: #333;
  text-align: center;
  margin-top: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

input[type="text"] {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #4caf50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #45a049;
}

p {
  text-align: center;
  font-size: 18px;
  color: #555;
}

/* 単語ネットワークのページ用 */
#network-graph-container {
  flex: 1; /* 画面の残りの高さを占有 */
  display: flex;
  justify-content: center;
  align-items: center;
}

#network-graph {
  width: 95%;
  height: 80vh; /* 画面の80%の高さを占有 */
  max-height: 500px; /* 最大高さを500pxに制限 */
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* フッター */
footer {
  text-align: center;
  padding: 10px;
  font-size: 12px;
  background-color: #f4f4f4;
}

/* レスポンシブ対応 */
@media (max-width: 600px) {
  nav ul {
    flex-direction: row; /* スマホでも横並びを維持 */
    flex-wrap: wrap; /* 幅に応じて折り返し */
  }

  nav ul li {
    margin: 5px 5px; /* 小さな画面で余白を調整 */
  }

  nav ul li a {
    font-size: 16px; /* フォントサイズを小さく */
    padding: 8px 12px; /* 余白を調整 */
  }

  h2 {
    font-size: 20px; /* タイトルのフォントサイズを小さく */
  }

  input[type="text"] {
    width: 95%; /* 入力フィールドの幅を広げる */
  }

  button {
    width: 90%; /* ボタンの幅を広げる */
    font-size: 14px; /* ボタンのフォントサイズを小さく */
  }

  #network-graph {
    width: 95%;
    height: 50vh; /* 高さを50%に変更 */
  }

  p {
    font-size: 16px; /* 段落のフォントサイズを小さく */
  }
}
