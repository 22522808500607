/* src/components/HomePage.css */
.app-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.app-card {
  text-decoration: none;
  color: inherit;
  width: 300px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s;
}

.app-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-content {
  display: flex;
  flex-direction: column;
}

.app-thumbnail {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

h2 {
  margin: 10px;
  font-size: 18px;
}

p {
  margin: 0 10px 10px;
  font-size: 14px;
  color: #666;
}
