.App {
  text-align: center;
  margin: 20px;
}

.tanka-box {
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px 0;
  background-color: #f9f9f9;
}
.menu-bar {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  background-color: #333;
}

.menu-bar li {
  margin: 0 15px;
}

.menu-bar li a {
  color: white;
  text-decoration: none;
}

.menu-bar li a:hover {
  text-decoration: underline;
}

.tanka-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.tanka-vertical {
  border: 1px solid black;
  padding: 20px;
  writing-mode: vertical-rl;
}
button {
  margin-top: 10px;
  padding: 5px 10px;
  font-size: 16px;
}

h1 {
  text-align: center;
  margin-top: 20px;
  font-size: 24px;
}

.card-container {
  display: flex;
  flex-wrap: wrap; /* カードを折り返す */
  justify-content: center; /* 中央揃えにする */
  gap: 10px; /* カード間のスペース */
  max-width: 100%; /* 親要素に合わせた最大幅 */
  margin: 0 auto; /* 自動的に中央寄せ */
  padding: 10px; /* 余白を追加して見やすく */
}

.hiragana-card {
  flex: 0 1 50px; /* 幅を固定しつつ、折り返しを許可 */
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 8px;
  text-align: center;
  font-size: 24px;
  width: 50px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  user-select: none;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

/* スマートフォン向けのスタイル */
@media (max-width: 600px) {
  .hiragana-card {
    flex: 0 1 30%; /* スマートフォンでは3列に並ぶように調整 */
    margin-bottom: 5px; /* カード間のスペースを小さく */
  }
}

.hiragana-card:hover {
  transform: translateY(-2px);
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.15);
}

.text-area-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.text-area {
  font-size: 16px;
  width: 100%;
  max-width: 400px; /* テキストエリアの最大幅を指定 */
  height: 100px; /* 高さを調整 */
  resize: none; /* サイズ変更を無効に */
  overflow-y: auto; /* 縦方向のスクロールを許可 */
  overflow-x: hidden; /* 横方向のスクロールは非表示 */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  white-space: pre-wrap; /* 改行をそのまま表示 */
}

.button-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.styled-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.styled-button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}

.styled-button:active {
  background-color: #3e8e41;
  transform: translateY(0);
}

.capture-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #fff; /* 背景色を指定することで、透明部分を防止 */
}
